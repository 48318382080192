.page-reviews {
	max-width: 1230px;
	margin: 0 auto;
	padding: 50px 15px;

	&__list {
		display: flex;
		gap: 80px;
		flex-direction: column;
		margin-bottom: 30px;

	}

	.item {
		border-top: 1px solid silver;
		padding-top: 20px;
		&__top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			&-name {
				font-weight: bold;
			}

		}
	}

	.form-main {
		text-align: center;
		background: linear-gradient(rgb(170,226,106) 0%,rgb(121,184,51) 47%,rgb(116,181,43) 100%);
		box-shadow: 0 5px 5px 0 rgba(0,0,0,.17);
		padding: 50px;
		border-radius: 32px;

		.title {
			font-size: 24px;
			margin-bottom: 30px;
			font-weight: bold;
		}




		&__form-bottom {
			display: grid;
			grid-template-columns: auto auto;
			justify-content: center;
			gap: 50px;
			align-items: center;
		}


		.btn {
			font-size: 24px;
			color: #fff;
			text-shadow: 0 -1px 0 rgba(0,0,0,.62);
			font-weight: 400;
			background: -webkit-linear-gradient(#f97a39 0%,#ef7524 50%,#ed6416 51%,#e65e15 75%,#e05d15 100%);
			background: -o-linear-gradient(#f97a39 0%,#ef7524 50%,#ed6416 51%,#e65e15 75%,#e05d15 100%);
			background: linear-gradient(#f97a39 0%,#ef7524 50%,#ed6416 51%,#e65e15 75%,#e05d15 100%);
		}




	}

	// placeholder-container
	.placeholder-container {
		margin-bottom: 20px;
		position: relative;

		&__input {
			height: 50px;
		}

		&__textarea {
			height: 200px;
		}

		input, textarea {
			background-color: #fff;
			border-radius: 36px;
			color: inherit;
			padding: 20px;
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 2;
			border: 1px solid #ccc;
			&:focus {
				outline: 0;
				outline-offset: 0;
			}
		}

		.caption {
			position: absolute;
			transition: all 1s;
			top: 50%;
			transform: translate(20px, -50%);
			z-index: 2;
			color: #000;

			&__active {
				background-color: #fff;
				color: red;
				font-size: 12px;
				opacity: 1;
				top: 0;
				transform: translate(10px, -50%);
				padding: 0 15px;
				z-index: 3;
			}

		}

	}
}

@media (max-width: 560px) {
	.section-title {
		font-size: 23px;
		margin-bottom: 20px;
	}
}
